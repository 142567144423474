import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "",
  headers: {
    common: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  },
});

export default instance;
