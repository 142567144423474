import { useQuery } from "@tanstack/react-query";
import { axios } from "../utils";
import { Station } from "../types";
import { AxiosResponse } from "axios";

export default function useStations() {
  const retrieveStations = async () => {
    const response: AxiosResponse<Station[]> = await axios.get("stations");

    return response.data;
  };

  return useQuery<Station[]>({
    initialData: [],
    queryKey: ["stations"],
    queryFn: async () => await retrieveStations(),
    retry: 3,
  });
}
