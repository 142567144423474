import { lazy, Suspense } from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { nbNO } from "@mui/x-date-pickers/locales";
import { useStore } from "./hooks";
import { Header } from "./layout";
import "./App.scss";

const Index = lazy(() => import("./pages/Index"));
const AvailableCars = lazy(() => import("./pages/AvailableCars"));
const Archive = lazy(() => import("./pages/archive"));
const Bookings = lazy(() => import("./pages/bookings"));
const Contracts = lazy(() => import("./pages/contracts"));
const Login = lazy(() => import("./pages/Login"));

const AuthenticatedRoute = ({ isAuthenticated = false }) => {
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

const PublicRoute = ({ isAuthenticated = false }) => {
  return isAuthenticated ? <Navigate to="/" /> : <Outlet />;
};

const theme = createTheme({}, nbNO);

function App() {
  const { authToken } = useStore();
  return (
    <ThemeProvider theme={theme}>
      <Header />

      <main className="p-1">
        <Suspense
          fallback={
            <Container>
              <center>
                <Spinner />
              </center>
            </Container>
          }
        >
          <Routes>
            <Route
              path="/"
              element={
                <AuthenticatedRoute isAuthenticated={authToken !== null} />
              }
            >
              <Route path="/contracts" element={<Contracts />} />
              <Route path="/cars/available" element={<AvailableCars />} />
              <Route path="/archive" element={<Archive />} />
              <Route path="/bookings" element={<Bookings />} />
              <Route index element={<Index />} />
            </Route>
            <Route
              path="/"
              element={<PublicRoute isAuthenticated={authToken !== null} />}
            >
              <Route path="/login" element={<Login />} />
            </Route>
          </Routes>
        </Suspense>
      </main>
    </ThemeProvider>
  );
}

export default App;
