import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { axios } from "../utils";
import { CalendarBooking } from "../components/Calendar";
import { AxiosResponse } from "axios";

export default function useBookings(
  from: Date,
  to: Date | null = null,
  explicit: boolean = false,
) {
  const retrieveBookings = async (
    from: Date,
    to: Date | null,
    explicit: boolean,
  ): Promise<CalendarBooking[]> => {
    const response: AxiosResponse<CalendarBooking[]> = await axios.get(
      `bookings?from=${format(from, "yyyy-MM-dd")}${to !== null ? `&to=${format(to, "yyyy-MM-dd")}` : ""}${explicit === true ? "&explicit=true" : ""}`,
    );

    return response.data;
  };

  return useQuery<CalendarBooking[]>({
    initialData: [],
    queryKey: ["bookings", from, to, explicit],
    queryFn: async () => await retrieveBookings(from, to, explicit),
    refetchInterval: 1000 * 60 * 5,
    refetchIntervalInBackground: true,
    retry: 3,
  });
}
