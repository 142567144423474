import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useUser } from "../hooks";
const Header = () => {
  const { data: user } = useUser();
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">MSA Bilutleie AS</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {user !== null && (
          <Nav className="me-auto">
            <Link to="/" className="nav-link">
              Oversikt
            </Link>
            {/*
            <Link to="/contracts" className="nav-link">
              Kontrakter
            </Link>
            */}
            <Link to="/cars/available" className="nav-link">
              Tilgjengelige biler
            </Link>
            <Link to="/archive" className="nav-link">
              Arkiv
            </Link>
            <Link to="/bookings" className="nav-link">
              Bestillinger
            </Link>
          </Nav>
        )}
      </Container>
    </Navbar>
  );
};

export default Header;
