import { useQuery } from "@tanstack/react-query";
import { axios } from "../utils";
import { Car } from "../types";
import { AxiosResponse } from "axios";

export default function useAvailableCars() {
  const retrieveAvailableCars = async () => {
    const response: AxiosResponse<Car[]> = await axios.get("cars/available");

    return response.data;
  };

  return useQuery<Car[]>({
    initialData: [],
    queryKey: ["availableCars"],
    queryFn: async () => await retrieveAvailableCars(),
    refetchInterval: 1000 * 60 * 5,
    refetchIntervalInBackground: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    retry: 3,
  });
}
