import { useQuery } from "@tanstack/react-query";
import { axios } from "../utils";
import { AvailableCar, Car } from "../types";
import { AxiosResponse } from "axios";

export default function useCars() {
  const retrieveCars = async () => {
    const response: AxiosResponse<{
      cars: Car[];
      availableCars: AvailableCar[];
    }> = await axios.get("cars");

    return response.data.cars;
  };

  return useQuery<Car[]>({
    initialData: [],
    queryKey: ["cars"],
    queryFn: async () => await retrieveCars(),
    retry: 3,
  });
}
