import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useStore } from "../hooks";
import { AuthToken, Station, User } from "../types";
import { axios } from "../utils";
import { addSeconds, differenceInMilliseconds, isPast } from "date-fns";
export default function useUser() {
  const { authToken, setAuthToken } = useStore();

  if (authToken !== null) {
    axios.defaults.headers.common["Authorization"] =
      `${authToken.tokenType} ${authToken.accessToken}`;
  }

  const refreshToken = async (): Promise<AuthToken | null> => {
    try {
      const response: AxiosResponse<{
        token_type: "Bearer";
        access_token: string;
        refresh_token: string;
        expires_in: number;
      }> = await axios.post("/refresh", {
        refresh_token: authToken?.refreshToken,
      });

      return {
        tokenType: response.data.token_type,
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        expiresAt: addSeconds(new Date(), response.data.expires_in),
      };
    } catch (e) {
      return null;
    }
  };

  const retrieveUser = async (): Promise<User | null> => {
    if (isPast(authToken?.expiresAt as Date)) {
      const refreshedAuthToken = await refreshToken();
      if (refreshedAuthToken !== null) {
        axios.defaults.headers.common["Authorization"] =
          `${refreshedAuthToken.tokenType} ${refreshedAuthToken.accessToken}`;
      }
      setAuthToken(refreshedAuthToken);
    }

    if (authToken === null) {
      return null;
    }

    try {
      const response: AxiosResponse<User> = await axios.get("/users/current");

      return response.data;
    } catch (e) {
      return null;
    }
  };

  const setStation = async (station: Station) => {
    const response: AxiosResponse<{ success: boolean; message: string }> =
      await axios.put("/users/station", {
        id: station.id,
      });

    return response.data;
  };

  return {
    ...useQuery<User | null>({
      initialData: null,
      queryKey: ["user"],
      queryFn: async () => await retrieveUser(),
      throwOnError: false,
      retry: 3,
      refetchInterval: () =>
        Math.min(
          differenceInMilliseconds(
            new Date(authToken?.expiresAt as Date),
            new Date(),
          ),
          1000 * 60 * 5,
        ),
      refetchIntervalInBackground: true,
    }),
    setStation,
  };
}
