import { create } from "zustand";
import { AuthToken, CalendarView, User } from "../types";

type State = {
  authToken: AuthToken | null;
  setAuthToken: (authToken: AuthToken | null) => void;
  user: User | null;
  setUser: (user: User | null) => void;
  calendarView: CalendarView;
  setCalendarView: (calendarView: CalendarView) => void;
};

const useStore = create<State>((set) => ({
  authToken: JSON.parse(
    localStorage.getItem("accessToken") || "null",
  ) as AuthToken | null,
  setAuthToken: (authToken: AuthToken | null) => {
    if (authToken === null) {
      localStorage.removeItem("accessToken");
    } else {
      localStorage.setItem("accessToken", JSON.stringify(authToken));
    }

    return set((state) => ({
      ...state,
      authToken,
    }));
  },
  user: null,
  setUser: (user: User | null) => set((state) => ({ ...state, user })),
  calendarView: (localStorage.getItem("calendarView") as CalendarView) || "d",
  setCalendarView: (calendarView: CalendarView) => {
    localStorage.setItem("calendarView", calendarView);

    return set((state) => ({
      ...state,
      calendarView,
    }));
  },
}));

export default useStore;
